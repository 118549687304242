.product-item {
    display: flex;
    flex-direction: column;
    padding: 0px;
    text-align: center;
    width: auto;

    &:not(:last-of-type){
        border-right: 1px solid #bfbfbf;
    }

    .product-name {
        padding: 5px 5px;
        border-bottom: 1px solid #bfbfbf;
        font-size: 15px;
        font-weight: 500;
        min-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 130px;
        width: auto;
        white-space: nowrap;
        color: #5c5d61;
    }

    .product-size {
        background: white;
        color: #f1686e;
        font-size: 16px;
        font-weight: bold;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
}