.content {
    margin: auto;
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;

    .table {
        font-family: "Gotham";
        color: black;
    }

    .title {
        text-align: left;
    }
}