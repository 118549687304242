@import '../../assets/variables';

.modal{
    color: #000;
    display: grid;
    place-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .screen{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #333;
        opacity: .7;
        width: unset!important;
        z-index: 100;
    }
    .container{
        z-index: 101;
        background-color: #ddd;
        border: none;
        border-radius: 10px;
        padding: 20px;
        margin: auto;
        // max-width: 90%;
        width: unset!important;
        position: unset!important;
        top: unset!important;
        display: grid;
        place-content: center;
        height: 100%!important;
        
        .content{
            h3{
                text-transform: capitalize;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            margin: auto;
            white-space: break-spaces;
            display: flex;
            flex-direction: column;
            width: unset!important;
            position: unset!important;
        }
        
        .btns{
            width: unset!important;
            position: unset!important;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 20px;
            margin-top: 35px;
            button{
                border: none;
                border-radius: 50px;
                padding: 10px;
                color: #fff;
                font-weight: 700;
                min-width: 100px;
                cursor: pointer;
                &.ok-btn{
                    background-color: $mainClr;
                    &:hover{
                        background-color: darken($color: $mainClr, $amount: 5%);
                    }
                }
                &.cancel-btn{
                    background-color: #333;
                    &:hover{
                        background-color: darken($color: #333, $amount: 5%);
                    }
                }
            }
        }
    }
}