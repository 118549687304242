.category-row {
    display: flex;
    margin-bottom: 30px;
    background: #f5f5f5;
    width: fit-content;
    height: 84px;
    border-radius: 12px;
    border: solid 1px #bfbfbf;
    overflow: auto;

    .category-name {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5c5d61;
        padding: 0px 25px !important;
        min-width: 150px;
    }
}