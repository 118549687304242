.visualization {
  height: 100vh;
  max-height: 100vh;
  background-color: rgb(233, 232, 232);
  display: flex;

  .left-section {
    z-index: 20;
    width: 350px;
  }

  .arrow-left {
    position: fixed;
    left: 43px;
    top: 34px;
    cursor: pointer;
  }

  .title-section {
    padding-top: 87px;
    text-align: center;
    // height: 100px;
    // margin-left: 80px;
    width: auto;

    h1 {
      font-size: 20px;
      font-weight: 500;
      color: #5c5d61;
    }
  }
  iframe {
    border: none;
    width: 100%;
    // height: 100%;
    height: 100%;
    margin-left: -100px;
  }
  .start-over-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 73px;
    left: 100px;
    z-index: 50;


    .text {
        // font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.83;
        letter-spacing: normal;
        text-align: center;
        color: #f1686e;
        margin-left: 7px;
    }


  }
  
}
.no-size-container {
  height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-family: Gotham;
    font-size: 20px;
    font-weight: 500;
    color: #5c5d61;
  }
  .start-over-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .text {
        // font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.83;
        letter-spacing: normal;
        text-align: center;
        color: #f1686e;
        margin-left: 7px;
    }


  }
}


@media (max-width: 500px) {

  .visualization {
    flex-direction: column;
    padding-top: 40px;

    .left-section {
      width: 100%;
      .title-section {
        padding: 0;
      }
    }
    iframe {
      margin: auto;
      margin-top: 10px;
    }
    .start-over-container {
      // top: 0;
      left: 30px;
      top: calc(40px + 71px + 404px);
      height: fit-content;
      // width: 100%;

    }
  }
}
