.scan-result {
    display: flex;
    flex-direction: column;
    // gap: 28px;
    align-items: center;
    justify-content: center;
    padding-top: 33px;

    img.logo {
        width: 181px;
        height: 36px;
        margin-bottom: calc(16px + 28px);
    }

    .container {
        width: 310px;
        height: 252px;
        padding: 12px 0 32px;
        border-radius: 25px;
        background-color: #f5f5f5;
        display: flex;
        margin-bottom: 28px;
        // gap: 28px;
        flex-direction: column;
        align-items: center;
        position: relative;

        button.back {
            background-color: transparent;
            border: none;
            padding: 10px;
            line-height: 0.7;
            cursor: pointer;
            position: absolute;
            left: 12px;
            top: 5px;
            transition: 0.2s;
            img {
                width: 9px;
                height: 15.7px;
            }
            &:hover {
                img {
                    transform: scale(1.2);
                    transition: 0.2s;
                }
            }
        }
        div {
            width: 181px;
            position: relative;
            // top: -28px;
            h6 {
                margin: 0;
                font-family: Gotham;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #5c5d61;
            }
        }
    }
    
    img.product-image {
        margin-top: 28px;
        width: 161px;
        height: 161px;
        border-radius: 50%;
        object-fit: contain;
        background-color: #dcdcdc;
    }

    .scan-again {
        margin-top: 43px;
        display: flex;
        // gap: 10px;
        align-items: center;
        justify-content: center;
        width: 225px;
        height: 41px;
        padding: 13px 18px 12px 21px;
        border-radius: 21px;
        border: solid 3px #f1686e;
        background-color: #fff;
        font-family: Gotham;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f1686e;
        img {
            width: 21.3px;
            height: 15px;
            object-fit: contain;
            margin-right: 10px;
        }
    }

    // .start-over {
    //     position: absolute;
    //     top: 0;
    //     height: 100%;
    //     min-height: 460px;
    //     display: flex;
    //     align-items: flex-end;
    //     z-index: -1;
    //     button.reset {
    //         display: flex;
    //         align-items: center;
    //         background-color: transparent;
    //         border: none;
    //         cursor: pointer;
    //         padding: 20px;
    //         font-family: Gotham;
    //         font-size: 12px;
    //         font-weight: bold;
    //         font-stretch: normal;
    //         font-style: normal;
    //         line-height: 2.83;
    //         letter-spacing: normal;
    //         text-align: center;
    //         color: #5c5d61;
    //         margin: 0 auto 10px;
    //         img {
    //             width: 11.8px;
    //             height: 11.8px;
    //             margin: 1.5px 2.9px 1.7px 0;
    //             object-fit: contain;
    //         }
    //         transition: 0.2s;
    //         &:hover {
    //             transform: scale(1.2);
    //             transition: 0.2s;
    //         }
    //     }
    // }
}
