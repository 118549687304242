.loader{
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    place-content: center;
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    z-index: 100;
    cursor: progress;
    img{
        width: 10vw;
        margin: 0 auto 40px;
        animation-name: rotate;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    @keyframes rotate {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
}