.switch-button-img{
    width: 100%;
}
.switch-button-img .switch-container{
  display: flex;
  flex-direction: row;    
  cursor: pointer;
  justify-content: flex-start;
  width: 100%;
}
.switch-button.center{
  position: relative;
  left: 10.5%;
  top: 0;
}

.switch-button-img .switch-container.space-between{
  justify-content: space-between;
}

.switch-button-img .switch-button{
    display: flex;    
    max-width: 87px;
    /* font-size: 14px;
    margin: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;    
    padding: 10px 10px; */
    /* width: 40px;
    height: 40px;
    max-width: 100px; */
  }

.switch-button .switch-img-container div{
  font-size: 13px;
  width: calc(100% - 13px);
}

.switch-button-img img{
    margin-right: 13px;
    height: 109px;
    box-sizing: border-box;

}
.switch-button-img .switch-button:hover img{
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    /* border: solid 2px #cbcbcb; */
    /* border-radius: 13px; */
    /* max-width: 87.884px; */
  }
  .switch-button-img .switch-button.active img{
    border: solid 2px #f1686e !important;
    /* border-radius: 13px; */
    box-sizing: border-box;
  }
