@import "../../assets/variables";
@import "./scannerIframe";
@import "./qrcode";
@import "./ScanRes";

.scan {
    display: flex;
    flex-direction: column;
    // gap: 23px;
    align-items: center;
    justify-content: center;
    padding-top: 33px;
    background-color: #f5f5f5;

    .logo {
        width: 181px;
        height: 36px;
    }

    .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
        .qrcode {
            margin-inline-end: 10px;
            &.show {
                &::before {
                    content: "";
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(51, 51, 51, 0.65);
                }
                &::after {
                    content: "";
                    background-color: #fff;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    transform: scale(3);
                    position: absolute;
                    top: 25vh;
                }
                background-color: unset;
                backdrop-filter: blur(5px);
                position: fixed;
                display: flex;
                justify-content: center;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                margin: 0;
                z-index: 100;
                img,.qrcode__img {
                    position: relative;
                    transform: scale(2.5);
                    top: 25vh;
                    z-index: 101;
                    height: 60px;
                    width: 60px;
                }
                label {
                    display: inline-block;
                    position: absolute;
                    top: 65vh;
                    left: 0;
                    width: 100vw;
                    color: #fff;
                }
            }
        }
    }

    h4 {
        margin: 0 0 6px 0;
        font-family: Gotham;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5d61;
        text-align: start;
    }
    h5 {
        margin: 0;
        font-family: Gotham;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c5f63;
        text-align: start;
    }
    h6 {
        margin: 0;
        font-family: Gotham;
        font-size: 12px;
        font-weight: 100;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #999a9c;
        text-align: start;
    }

    .heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-inline-end: 10px;
        flex-grow: 1;
    }

    .white-box {
        background-color: #fff;
        border-radius: 30px 30px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        width: 100%;
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .more-actions-title {
                margin: 40px 0px;
            }
            
            .action-box {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .circle {
                    background-color: #f5f5f5;
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-inline-end: 10px;
                    img {
                        height: 60%;
                    }
                }
                &.scanner-box {
                    margin-bottom: 40px;
                    .circle {
                        img {
                            transform: translateX(-1px);
                        }
                    }
                }
                &.instant-app {
                    .circle {
                        img {
                            transform: rotate(16deg);
                        }
                    }
                }
                button,
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    text-decoration: none;
                    width: 100%;
                    transition: 0.2s;
                    img[alt="arrow"] {
                        transform: rotate(180deg);
                        height: 10px;
                    }
                    &:hover {
                        background-color: #ddd;
                        transition: 0.2s;
                    }
                    &:disabled {
                        cursor: not-allowed;
                        filter: grayscale(1);
                    }
                }
                &.in-app a {
                    transition: 0.2s;
                    &:hover {
                        background-color: darken($color: $mainClr, $amount: 5%);
                        transition: 0.2s;
                    }
                }
            }
        }
    }
}

.start-over {
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    z-index: -1;
    margin: auto;
    width: 100%;
    button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 7px;
        font-family: Gotham;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.83;
        letter-spacing: normal;
        text-align: center;
        color: #5c5d61;
        margin: 0 auto 10px;
        img {
            width: 11.8px;
            height: 11.8px;
            margin: 1.5px 2.9px 1.7px 0;
            object-fit: contain;
        }
        transition: 0.2s;
        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }
}