.scanner-iframe{
    height: 100vh;

    .logo {
        width: 181px;
        height: 36px;
        margin: 30px 0;
    }

    // iframe{
    //     width: 100vw;
    //     height: 270px;
    // }

    p{
        font-size: 16px;
        font-weight: 100;
        margin: 40px 0;
        color: #777;
    }

    button{
        border-radius: 80px;
        background-color: $mainClr;
        color: #fff;
        border: none;
        width: 80%;
        padding: 15px 0;
        font-weight: 700;
    }
}