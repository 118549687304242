.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.logo-image {
  width: calc(100% - 50% - 31px);
  margin: 40px 0px;
  max-height: 150px;
}
form {
  position: relative;
  top: 20px;
}

input::-webkit-inner-spin-button {
  cursor: pointer;
}

input.full-input::-webkit-inner-spin-button {
  margin-right: 15px;
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.form-container,
.select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px;
  width: 100%;
  color: #5c5d61;

  p {
    font-size: 15px;
    font-weight: bold;
    padding: 0 12px;
  }
  div.radio-btns {
    display: flex;
    gap: 2rem;
    padding: 0 12px;
  }
}
.image-container {
  //margin-top: 25px;
}
.input-radio-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  input {
    visibility: hidden;
  }
  span {
    margin-left: 17px;
  }
  .container-radio-btn-marker {
    margin: 0;
    padding: 0;
    //width: 15px;
    //height: 15px;
    //border: 2px solid #f1686e;
  }

  input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: 13px;
    left: 3px;
    position: relative;
    background-color: #fff;
    content: "";
    display: inline-block;
    visibility: visible;
    outline: 2px auto #f1686e;
    cursor: pointer;
  }

  input[type="radio"]:checked:after {
    width: 9px;
    height: 9px;
    border-radius: 15px;
    top: 13px;
    left: 3px;
    position: relative;
    background-color: #f1686e;
    content: "";
    display: inline-block;
    visibility: visible;
    outline: 2.5px auto #f1686e;
    border: 4.5px solid #fff;
    transform: translate(-1px, -1px);

    // support for Safari 9.0 and up only
    @supports (-webkit-hyphens: none) {
      border: 2.5px solid #fff;
      transform: none;
    }
  }
}
.full-input {
  width: 100%;
}
.input-label {
  left: 0;
  font-family: Gotham;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5c5d61;
}
.top-buttons {
  width: 100%;
  position: absolute;
  top: 0;
}
.arrow-btn {
  position: absolute;
  left: 50px;
  top: 33px;
  background: #fff;
  border: none;
  cursor: pointer;
}
input {
  border-radius: 21px;
  background-color: #f5f5f5;
  border: none;
  margin: 0 auto;
  padding-left: 20px;
  height: 41px;
}
.input-height-inches {
  display: flex;
  justify-content: space-between;
  width: 100%;
  input {
    max-width: 49%;
    margin: 0;
    padding: 0 15px;
  }
  &.height-error {
    input {
      background-color: #f5d4d4;
      border: 1px solid red;
    }
    &::after {
      content: "Please fill correct height";
    }
  }
}
.submit-button {
  // width: 335px;
  width: 100%;
  height: 41px;
  border-radius: 21px;
  background-color: #f1686e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  margin: 39px 0;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  &:disabled, &.error {
    cursor: not-allowed;
    background-color: #5c5d61;
    &.clicked ~ .error-msg{
      &.shown{
        opacity: 1;
        transition: 0.3s;
      }
    }
  }
}
.error-msg {
  opacity: 0;
  color: red;
  margin: 0;
  transform: translateY(-35px);
  display: block;
  font-size: 0.7rem;
  transition: 0.3s;
  // .submit-button:hover + &.shown {
  // &.shown {
  //   opacity: 1;
  //   transition: 0.3s;
  // }
}
.select-container {
  &-label {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5c5d61;
    padding: 12px;
  }
  .bra-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .header {
      position: relative;
      height: 40px;
      .title {
        justify-content: center;
        transform: translateY(10px);
        font-size: 14px;
        font-weight: bold;
        padding-left: 12px;
        display: inline-block;
      }


      .bra__unit {
        display: inline-block;
        position: absolute;
        right: 0;
      }
    }
    .selectors {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
  .select-container-label {
    margin-top: 15px;
    text-align: center;
  }
}

.select-input {
  flex-grow: 1;
  border-radius: 21px;
  background-color: #f5f5f5;
  color: black;
  height: 41px;
  border: none;
  
  // text-align: center;
  padding-left: 60px;

  margin: 5px 0;
  font-size: 13px;
  width: 155px;
  outline-color: transparent;

  // support for Safari 9.0 and up only
  @supports (-webkit-hyphens: none) {
    // text-indent: calc(50% - 1.5ch);
  }
 


  cursor: pointer;
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Positions background arrow image */
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAHBAMAAAAhXtopAAAAKlBMVEVHcEwYGiAXGiEXGSEVFx4XGyAWGSAWGx8YGyAYGyAWFiEYGBgWGyEYGiEuR+4FAAAADnRSTlMAgJtkS9GRcra1LhUv6hZOqTkAAAAvSURBVAjXY8hmAAKuAgbeA0DaFYhDGBiYG4A0pwGDEEiOQZFJAEwzJjNAgAMQAwCO0QPx7X2/cQAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: 90% center;

  &:focus{
    outline: none;
  }
}

.validation-error {
  padding-top: 6px;
  box-sizing: border-box;
  font-size: 14px;
  color: #b74134;
  align-self: flex-start;
  margin: 0;
  margin-left: 30px;
}

.form {
  .left-arrow {
    position: fixed;
    left: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 21;
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  &:focus{
    outline: none !important;
  }
}

@media (max-width: 500px) {
  .form-container {
    width: 310px;
  }
  .form {
    .left-arrow {
      position: fixed;
      left: 19px;
      top: 16px;
      width: 5px;
      height: 9px;
      z-index: 21;
      cursor: pointer;
    }
  }
}
