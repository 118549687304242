.basic-info {
    display: flex;
    justify-content: center;

    .form-container {
        justify-content: center;
        align-items: center;
        margin: 0px 40px;
    }

    .logo-frame {
        width: 110%;
        max-width: 425px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .separator-line {
            min-width: 2px;
            height: 50px;
            margin-right: 30px;
            margin-left: 30px;
            background: #cecece;
        }
    }

    input{
        &:focus{
            outline: none;
        }
    }

    .form-email {
        .input-label {
            padding: 10px;
        }
    }
}