// html,body{
//     height: 100%; 
//     width: 100%;
// }

.qrcode{
    background-color: #fff;
    padding: 20px;
    border-radius: 50%;
    img{
        box-sizing: content-box;
        width: 60px;
        transform: translate(1px, 3px);
        // height: 65px;
        fill: #000;
    }
    
    label{
        display: none;
    }
    
    &.loading{
        img,.qrcode__img{
            background: transparent url('../../assets/images/loader-gif.gif') center no-repeat;
        }
    }
}