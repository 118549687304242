.advance-info {
  min-width: 282px;
  max-width: 350px;
  margin: auto;
  padding-top: 20px;

  .female-section {
    width: inherit;
    text-align: center;

    &__question-container {
      margin-bottom: 15px;
    }
    .bra-container {
      width: inherit;
    }
  }

  .belly-container,
  .hip-container {
    width: 100%;
    text-align: center;
  }

  .hip-container {
    .switch-button-img .switch-container{
      justify-content: flex-end;
      .switch-button.center {
        left: unset;
        margin-left: 44px;
      }
    }
  }

  .unclickable {
    pointer-events: none !important;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #f1686e !important;
  }
  .MuiRadio-colorSecondary {
    color: #f1686e !important;
  }
  .MuiTypography-body1 {
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
  }


  .male-container {
    margin-top: 40px;
  }
}
