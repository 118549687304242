.switch-big-circle{
  width: 100%;
}
.switch-big-circle .switch-container{
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: space-between;
  width: 100%;
}
.switch-big-circle .switch-button{     
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  background-color: #f5f5f5;
  height: 41px;
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 10px;  
  border-radius: 21px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #181b21;
}
.switch-big-circle .active{    
  border: solid 3px #f1686e;
  background-color: #f5f5f5;
  color: #f1686e;
}
